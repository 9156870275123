import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import html from "../../icons/html.svg";
import js from "../../icons/js.svg";
import json from "../../icons/json.svg";
import php from "../../icons/php.svg";
import info from "../../icons/info.svg";

library.add(faTimesCircle);

const Header = ({ showPage, setShowPage, hidePage, setHidePage, language, reloadPage }) => {
    return (
        <nav id="header">
            <ul>
                {
                    hidePage.includes("not-found") ? null :
                    <li className={showPage === 'not-found' ? 'active' : ''}>
                        <div className="menu-item not-found" onClick={() => setShowPage('not-found')}>
                            <FontAwesomeIcon className="menu-icon not-found-icon" size="lg" icon="times-circle" />
                            <span className="not-found-tab"><em>{language.not_found}</em></span>
                            <p 
                                className={showPage === 'not-found' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    reloadPage();
                                }}
                            >×</p>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("home") ? null :
                    <li className={showPage === 'home' ? 'active' : ''}>
                        <div className="menu-item home-item" onClick={() => setShowPage('home')}>
                            <img className="menu-icon" src={html} alt=""/>
                            <span>{language.home}.html</span>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("about") ? null :
                    <li className={showPage === 'about' ? 'active' : ''}>
                        <div className="menu-item" onClick={() => setShowPage('about')}>
                            <img className="menu-icon" src={js} alt="" />
                            <span>{language.about}.js</span>
                            <p 
                                className={showPage === 'about' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage("home");
                                    setHidePage(prevState=>[...prevState, "about"]);
                                }}
                            >×</p>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("summary") ? null :
                    <li className={showPage === 'summary' ? 'active' : ''}>
                        <div className="menu-item" onClick={() => setShowPage('summary')}>
                            <img className="menu-icon" src={json} alt="" />
                            <span>{language.summary}.json</span>
                            <p 
                                className={showPage === 'summary' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage("home");
                                    setHidePage(prevState=>[...prevState, "summary"]);
                                }}
                            >×</p>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("services") ? null :
                    <li className={showPage === 'services' ? 'active' : ''}>
                        <div className="menu-item" onClick={() => setShowPage('services')}>
                            <img className="menu-icon" src={html} alt="" />
                            <span>{language.services}.html</span>
                            <p 
                                className={showPage === 'services' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage("home");
                                    setHidePage(prevState=>[...prevState, "services"]);
                                }}
                            >×</p>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("contact") ? null :
                    <li className={showPage === 'contact' ? 'active' : ''}>
                        <div className="menu-item" onClick={() => setShowPage('contact')}>
                            <img className="menu-icon" src={php} alt="" />
                            <span>{language.contact}.php</span>
                            <p 
                                className={showPage === 'contact' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage("home");
                                    setHidePage(prevState=>[...prevState, "contact"]);
                                }}
                            >×</p>
                        </div>
                    </li>
                }
                {
                    hidePage.includes("policy") ? null :
                    <li className={showPage === 'policy' ? 'active' : ''}>
                        <div className="menu-item policy" onClick={() => setShowPage('policy')}>
                            <img className="menu-icon" src={info} alt="" />
                            <span>{language.policy}.md</span>
                            <p 
                                className={showPage === 'policy' ? 'close-tab' : 'close-tab hidden'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPage("home");
                                    setHidePage(prevState=>[...prevState, "policy"]);
                                }}
                            >×</p>
                        </div>
                    </li>
                }                
            </ul>
        </nav>
    );
};

export default Header;