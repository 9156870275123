import React, { useEffect } from 'react'

const NotFound = ({ setShowPage, hidePage, setHidePage, reloadPage, language }) => {
    
    useEffect(() => {
        setShowPage('not-found');
        if(hidePage.includes("not-found")) hidePage.splice(hidePage.indexOf("not-found"), 1);
        if(!hidePage.includes("home")) setHidePage(prevState=>[...prevState, "home"])
        if(!hidePage.includes("about")) setHidePage(prevState=>[...prevState, "about"])
        if(!hidePage.includes("summary")) setHidePage(prevState=>[...prevState, "summary"])
        if(!hidePage.includes("services")) setHidePage(prevState=>[...prevState, "services"])
        if(!hidePage.includes("contact")) setHidePage(prevState=>[...prevState, "contact"])
    });

    return (
        <div id="not-found" className="wrapper">
            <h1 className="red">404 <br/>{language.not_found}</h1>
            <h2 className="cian">Oh no...</h2>
            <h5 className="white">{language.msg}</h5>
            <button 
                className="not-found-btn"
                onClick={(e) => {
                    e.stopPropagation();
                    reloadPage();
                }}
            >{language.btn}</button>
        </div>
    );
};

export default NotFound;