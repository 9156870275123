import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope } from "@fortawesome/free-solid-svg-icons";

library.add(faLocationDot, faEnvelope);

const Footer = ({ setShowPage, hidePage, language, reloadPage }) => {
    const year = new Date().getFullYear();
    return (
        <div id="footer">
            <div>
                <span>&copy; {year} Copyright <strong>Agustin Toth</strong> - {language.copy}</span>
            </div>
            <div className="info">
                <div>
                    <FontAwesomeIcon icon="fas fa-location-dot" />
                    <span>Montevideo, Uruguay</span>
                </div>
                <div>
                    <FontAwesomeIcon icon="fas fa-envelope" />
                    <a href="mailto:info@agustintoth.xyz" target="_blank" rel="noreferrer">info@agustintoth.xyz</a>
                </div>
                <span onClick={() => {
                    reloadPage('policy');
                    setShowPage('policy');
                    if(hidePage.includes("policy")) hidePage.splice(hidePage.indexOf("policy"), 1)
                }}>
                    {language.policy}
                </span>
            </div>
        </div>
    );
};

export default Footer;