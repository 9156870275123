import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

let About = ({ language }) => {
    return (
        <div id="about" className="wrapper">
            <h2>{'<'}<span className="cian"><em>{language.about}</em></span>{' />'}</h2>
            <div className="wrapper-description">
                <p>{language.firstLine}</p>
                <p>{language.secondLine}</p>
                <p>{language.thirdLine}</p>
                <p>{language.fourthLine}</p>
            </div>
            <div className="skills white">
                <div>
                    <h3 className="green">{language.design}<span className="purple">{'()'}</span><span className="yellow">{' {'}</span></h3>
                    <div className="padding-left">
                        <p><span className='cian'><em>const</em></span> Figma;</p>
                        <p><span className='cian'><em>const</em></span> Photoshop;</p>
                        <p><span className='cian'><em>const</em></span> Illustrator;</p>
                    </div>
                    <h3 className="class-style yellow">{'}'}</h3>
                </div>
                <div>
                    <h3 className="green">frontEnd<span className="purple">{'()'}</span><span className="yellow">{' {'}</span></h3>
                    <div className="padding-left">
                        <p><span className='cian'><em>const</em></span> HTML;</p>
                        <p><span className='cian'><em>const</em></span> CSS;</p>
                        <p><span className='cian'><em>const</em></span> Bootstrap;</p>
                        <p><span className='cian'><em>const</em></span> JavaScript;</p>
                        <p><span className='cian'><em>const</em></span> VueJS;</p>
                        <p><span className='cian'><em>const</em></span> React;</p>
                    </div>
                    <h3 className="class-style yellow">{'}'}</h3>
                </div>
                <div>
                    <h3 className="green">backEnd<span className="purple">{'()'}</span><span className="yellow">{' {'}</span></h3>
                    <div className="padding-left">
                        <p><span className='cian'><em>const</em></span> PHP;</p>
                        <p><span className='cian'><em>const</em></span> SQL;</p>
                        <p><span className='cian'><em>const</em></span> NoSQL;</p>
                        <p><span className='cian'><em>const</em></span> NodeJS;</p>
                        <p><span className='cian'><em>const</em></span> AWS;</p>
                    </div>
                    <h3 className="class-style yellow">{'}'}</h3>
                </div>
            </div>
        </div>
    );
};

export default About;