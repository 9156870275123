import Main from './components/Main';
import './styles/style.css';

function App() {
  return (
    <main className="main">
      <Main />
    </main>
  );
}
export default App;