import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Dashboard from './Dashboard';

const Main = () => {
    return(
        <Router>
            <Routes>
                <Route path="*" element={<Dashboard/>} />
                <Route path="/" element={<Dashboard/>} />
            </Routes>
        </Router>
    );
}
export default Main;