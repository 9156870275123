import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

const Policy = ({ language }) => {
    return (
        <div id="policy" className="wrapper">
            <h2>{'<'}<span className="cian">{language.privacy_policy}</span>{' />'}</h2>
            <div className="polices-info cian">
                <span>** www.agustintoth.xyz **</span>
                <span>** {language.last_updated} **</span>
            </div>
            <div className="white">
                <div>
                    <h4 className="green">## {language.policy.one.title}</h4>
                    <p>{language.policy.one.section_1}</p>
                    <p>{language.policy.one.section_1}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.two.title}</h4>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.two.section_1.title}`</span></h5>
                    <p>{language.policy.two.section_1.text}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.two.section_2.title}`</span></h5>
                    <p>{language.policy.two.section_2.text}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.two.section_3.title}`</span></h5>
                    <p>{language.policy.two.section_3.text_1}</p>
                    <p>{language.policy.two.section_3.text_2}</p>
                    <p>{language.policy.two.section_3.text_3}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.two.section_4.title}`</span></h5>
                    <p>{language.policy.two.section_4.text}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.two.section_5.title}`</span></h5>
                    <p>{language.policy.two.section_5.text}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.three.title}</h4>
                    <p>{language.policy.three.section_1.title}</p>
                    <div className="padding-left">
                        <p>{language.policy.three.section_1.point_1}</p>
                        <p>{language.policy.three.section_1.point_2}</p>
                        <p>{language.policy.three.section_1.point_3}</p>
                        <p>{language.policy.three.section_1.point_4}</p>
                        <p>{language.policy.three.section_1.point_5}</p>
                        <p>{language.policy.three.section_1.point_6}</p>
                        <p>{language.policy.three.section_1.point_7}</p>
                        <p>{language.policy.three.section_1.point_8}</p>
                        <p>{language.policy.three.section_1.point_9}</p>
                        <p>{language.policy.three.section_1.point_10}</p>
                        <p>{language.policy.three.section_1.point_11}</p>
                        <p>{language.policy.three.section_1.point_12}</p>
                        <p>{language.policy.three.section_1.point_13}</p>
                        <p>{language.policy.three.section_1.point_14}</p>
                    </div>
                    <p>{language.policy.three.section_2.title}</p>
                    <div className="padding-left">
                        <p>{language.policy.three.section_2.point_1}</p>
                        <p>{language.policy.three.section_2.point_2}</p>
                        <p>{language.policy.three.section_2.point_3}</p>
                        <p>{language.policy.three.section_2.point_4}</p>
                    </div>
                </div>
                <div>
                    <h4 className="green">## {language.policy.four.title}</h4>
                    <p>{language.policy.four.section_1}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.four.section_2.title}`</span></h5>
                    <p>{language.policy.four.section_2.text}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.four.section_3.title}`</span></h5>
                    <p>{language.policy.four.section_3.text}</p>
                    <h5><span className="green">### </span><span className="orange">`{language.policy.four.section_4.title}`</span></h5>
                    <p>{language.policy.four.section_4.text}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.five.title}</h4>
                    <p>{language.policy.five.section_1}</p>
                    <p>{language.policy.five.section_2}</p>
                    <p>{language.policy.five.section_3}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.six.title}</h4>
                    <p>{language.policy.six.section_1}</p>
                    <p>{language.policy.six.section_2}</p>
                    <p>{language.policy.six.section_3}</p>
                    <p>{language.policy.six.section_4}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.seven.title}</h4>
                    <p>{language.policy.seven.section_1}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.eigth.title}</h4>
                    <p>{language.policy.eigth.section_1}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.nine.title}</h4>
                    <p>{language.policy.nine.section_1}</p>
                    <p>{language.policy.nine.section_2}</p>
                </div>
                <div>
                    <h4 className="green">## {language.policy.ten.title}</h4>
                    <p>{language.policy.ten.section_1}</p>
                    <p className="cian">** {language.policy.ten.section_2} **</p>
                </div>
            </div>
        </div>
    );
};

export default Policy;