import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

import About from './About';
import Summary from './Summary';
import Services from './Services';
import Contact from './Contact';


const Home = ({ hidePage, language }) => {
    return (
        <div id="home">
            <div className="wrapper home">
                <span>{language.home.first}</span>
                <h1 className="red">Agustin Toth</h1>
                <span>{language.home.second}</span>
           </div>
            <About language={language.about}/>
            <Summary language={language.summary}/>
            <Services language={language.services}/>
            <Contact language={language.contact}/>
        </div>
    );
};

export default Home;