import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';

const Services = ({ language }) => {
    return (
        <div id="services" className="wrapper">
            <h2>{'<'}<span className="cian">{language.services}</span>{' />'}</h2>
            <div className="cards-container wrapper-description">
                <div className="card-style card-blue">
                    <img src="" alt="" />
                    <h4>{language.webapp.title}</h4>
                    <p>{language.webapp.description}</p>
                </div>
                <div className="card-style card-purple">
                    <img src="" alt="" />
                    <h4>{language.design.title}</h4>
                    <p>{language.design.description}</p>
                </div>
                <div className="card-style card-red">
                    <img src="" alt="" />
                    <h4>{language.hosting.title}</h4>
                    <p>{language.hosting.description}</p>
                </div>
                <div className="card-style card-orange">
                    <img src="" alt="" />
                    <h4>{language.consultancy.title}</h4>
                    <p>{language.consultancy.description}</p>
                </div>
            </div>
        </div>
    );
};

export default Services;